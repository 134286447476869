import { motion, AnimatePresence } from 'framer-motion';
import NavLink from './NavLink';
import React from 'react';
interface MobileMenuProps {
  isOpen: boolean;
  navLinks: Array<{ to: string; label: string }>;
  onLinkClick: () => void;
}

const menuVariants = {
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      when: "afterChildren"
    }
  },
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  closed: {
    x: -20,
    opacity: 0
  },
  open: {
    x: 0,
    opacity: 1
  }
};

const MobileMenu = ({ isOpen, navLinks, onLinkClick }: MobileMenuProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={menuVariants}
          className="md:hidden overflow-hidden"
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navLinks.map((link) => (
              <motion.div
                key={link.to}
                variants={itemVariants}
              >
                <NavLink
                  to={link.to}
                  label={link.label}
                  onClick={onLinkClick}
                  isMobile
                />
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;