import React from 'react';

export function EmailHeader() {
  return (
    <div className="text-4xl font-semibold mb-6">
      hi.rupesh
      <br />
     @gmail.com
    </div>
  );
}