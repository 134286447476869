import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import React from 'react';
interface CertificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  title: string;
  organization: string;
}
export function CertificationModal({
  isOpen,
  onClose,
  imageUrl,
  title,
  organization
}: CertificationModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/90 z-50"
          />
          
          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-4 z-50 overflow-auto flex items-center justify-center"
          >
            <div className="relative max-w-5xl w-full bg-gray-900 rounded-xl p-2">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 rounded-full bg-gray-800/50 text-gray-400 hover:text-white transition-colors duration-200"
              >
                <X className="w-5 h-5" />
              </button>
              
              {/* Certificate content */}
              <div className="space-y-4">
                <img
                  src={imageUrl}
                  alt={title}
                  className="w-full rounded-lg"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold text-white">{title}</h3>
                  <p className="text-gray-400">{organization}</p>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}