import React from 'react';
import { motion } from 'framer-motion';
import { Award, Shield } from 'lucide-react';

const proficiencyColors = {
  Beginner: 'bg-blue-900/50 text-blue-200 border-blue-700',
  Intermediate: 'bg-emerald-900/50 text-emerald-200 border-emerald-700',
  Advanced: 'bg-purple-900/50 text-purple-200 border-purple-700',
  Expert: 'bg-orange-900/50 text-orange-200 border-orange-700',
};

const proficiencyIcons = {
  Beginner: 1,
  Intermediate: 2,
  Advanced: 3,
  Expert: 4,
};

export const SkillCard = ({ title, company, proficiency, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ scale: 1.02 }}
      className="bg-gray-800 m-auto w-full  max-w-sm rounded-lg  p-4  border border-gray-700 hover:border-gray-600 transition-all"
    >
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-semibold text-white">{title}</h3>
          <p className="text-sm text-gray-400 mt-1">{company}</p>
        </div>
        <Award className="text-blue-400 w-6 h-6" />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <span
          className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium border ${proficiencyColors[proficiency]}`}
        >
          {proficiency}
        </span>
        <div className="flex gap-1">
          {[...Array(proficiencyIcons[proficiency])].map((_, i) => (
            <Shield key={i} className="w-4 h-4 text-gray-500" fill="currentColor" />
          ))}
        </div>
      </div>
    </motion.div>
  );
};
export const SkillsGrid = ({ skills }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {skills.map((skill, index) => (
        <SkillCard key={index} {...skill} delay={index * 0.2} />
      ))}
    </div>
  );
};
