import React from 'react';
import SocialLinks from './SocialLinks';
import ProfileImage from './ProfileImage';
import Typewriter from 'typewriter-effect';
import { Bio } from '../data/constants';
import { Link } from 'react-scroll'; 
import ParticleBackground from '../data/Particle';

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-black">
      {/* Particle container */}
      <div className="absolute inset-0 z-0">
        <ParticleBackground />
      </div>

      {/* Main content */}
      <section className="relative z-10 flex flex-col  lg:flex-row items-center justify-center px-4 sm:px-6 lg:px-8 py-8 lg:pb-28 min-h-screen">
        <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-screen-xl gap-8 lg:gap-12">
          {/* Mobile Layout */}
          <div className="block lg:hidden w-full">
            <div className="flex flex-col items-center gap-6">
              <div className="w-64 h-64">
                <ProfileImage />
              </div>
              <div className="text-3xl sm:text-5xl font-bold flex flex-wrap items-center gap-2 sm:gap-3 justify-start text-white">
      <h1 className="text-2xl sm:text-4xl ml-1 sm:ml-2">Rupesh Devkota</h1>
      <span 
        role="img" 
        aria-label="waving hand" 
        className="animate-pulse text-2xl sm:text-3xl"
      >
        👋
      </span>
    </div>
              <div className="flex justify-center">
                <SocialLinks />
              </div>

              {/* Typewriter effect */}
              <div className="flex items-center gap-2 text-gray-400 justify-center">
                <div className="text-sm sm:text-base">
                  <Typewriter
                    options={{
                      strings: Bio.roles,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </div>
              </div>

              {/* Explore Blogs Button */}
              <div className="flex justify-center w-full mt-6">
                <Link
                  to="blog"
                  smooth={true}
                  duration={500}
                  className="bg-emerald-300 text-black px-4 py-2 rounded-full hover:bg-emerald-400"
                >
                  Explore Blogs
                </Link>
              </div>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden lg:block space-y-8 w-full lg:max-w-lg text-left">
            <div className="flex justify-start">
              <SocialLinks />
            </div>

            <div className="space-y-6">
              <h1 className="text-5xl font-bold flex items-center gap-3 justify-start text-white">
                Rupesh Devkota
                <span role="img" aria-label="waving hand" className="animate-pulse cursor-custom">👋</span>
              </h1>

              <div className="flex items-center gap-2 text-gray-400 justify-start">
                <div className="w-12 h-[2px] bg-gray-400"></div>
                <div>
                  <Typewriter
                    options={{
                      strings: Bio.roles,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </div>
              </div>

              {/* Explore Blogs Button */}
              <div className="flex justify-start w-full mt-6">
                <Link
                  to="blog"
                  smooth={true}
                  duration={500}
                  className="bg-emerald-300 text-black px-4 py-2 rounded-full hover:bg-emerald-400"
                >
                  Explore Blogs
                </Link>
              </div>
            </div>
          </div>

          {/* Desktop Layout - Right Column */}
          <div className="hidden lg:block relative w-1/2 h-[500px]">
            <div className="relative w-full h-full flex items-center right-20 bottom-20 justify-end z-20 pointer-events-none">
              <div className="transform translate-y-24 translate-x-12 pointer-events-auto">
                <ProfileImage />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
