import { SkillCard } from './SkillCard';

const SkillsSection = ({ skills }) => {
  return (
    <div className="p-4 lg:mt-[-4vh]">
      {/* <h2 className="text-2xl font-bold text-white mb-4 ml-4"></h2> */}
      <div  className="grid  grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {skills.map((skill, index) => (
          <SkillCard
            key={index}
            title={skill.title}
            company={skill.company}
            proficiency={skill.proficiency}
            delay={index * 0.2}
          />
        ))}
      </div>
    </div>
  );
};

export { SkillsSection };
