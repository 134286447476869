import { motion } from 'framer-motion';
import { Award, ExternalLink, Eye } from 'lucide-react';
import { useState } from 'react';
import { CertificationModal } from './CertificationModel';
import React from 'react';

interface CertificationCardProps {
  title: string;
  organization: string;
  issueDate: string;
  expiryDate: string;
  imageUrl: string;
  skills: string[];
  credentialUrl: string;
  delay: number;
}

export function CertificationCard({
  title,
  organization,
  issueDate,
  expiryDate,
  imageUrl,
  skills,
  credentialUrl,
  delay
}: CertificationCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay }}
        className="relative group rounded-xl overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Certificate Image */}
        <div className="aspect-[4/3] relative">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
          />
          
          {/* Overlay on Hover */}
          <div className={`absolute inset-0 bg-black/80 transition-all duration-500 ease-in-out ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
            <div className="p-6 h-full flex flex-col justify-between">
              <div>
                <motion.h3 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : -10 }}
                  transition={{ duration: 0.3 }}
                  className="text-xl font-bold text-white mb-2"
                >
                  {title}
                </motion.h3>
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : -10 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="text-gray-300"
                >
                  {organization}
                </motion.p>
                <motion.p 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : -10 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="text-sm text-gray-400 mt-2"
                >
                  Issued {issueDate} 
                </motion.p>
                

              </div>
              
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : 20 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="flex gap-4 mt-4"
              >
                {/* Open credential URL if available */}
                {credentialUrl && (
                  <a
                    href={credentialUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors duration-300"
                  >
                    <ExternalLink className="w-4 h-4" />
                    <span className="text-sm">View Credential</span>
                  </a>
                )}
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors duration-300"
                >
                  <Eye className="w-4 h-4" />
                  <span className="text-sm">View Full</span>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
        {/* Badge Icon */}
        <div className="absolute top-4 right-4 bg-blue-500/20 p-2 rounded-full backdrop-blur-sm border border-blue-500/30">
          <Award className="w-5 h-5 text-blue-400" />
        </div>
      </motion.div>
      {/* Modal */}
      <CertificationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={imageUrl}
        title={title}
        organization={organization}
      />
    </>
  );
}
