import React from 'react';
import { Header } from './Header';
import { EmailHeader } from './EmailHeader';
import { ContactInfo } from './ContactInfo';
import { ImageSection } from './ImageSection';

function Contact() {
  return (
    <div className="min-h-fit py-5 bg-black">
      <div className="container mx-auto px-4 text-white max-w-7xl">
        {/* Header Section with proper spacing */}
        <div className="mb-4 md:mb-0">
          <Header />
        </div>

        {/* Main content */}
        <div className="top flex justify-center items-start flex-col md:flex-row gap-5 relative w-full">
          {/* Left Column - Contact Section */}
          <div className="flex-1 text-white bg-[#397082] p-8 rounded-sm w-full md:w-auto md:h-[60vh]">
            <EmailHeader />
            <hr className="border-t border-white w-[20%] mb-6" />
            <ContactInfo />
          </div>

          {/* Right Column - Image Section (Hidden on small devices) */}
          <div className="hidden md:block flex-1 md:h-[60vh]">
            <ImageSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;