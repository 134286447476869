import React, { useEffect } from 'react';
import DOMPurify from 'dompurify'; // For sanitizing HTM
const BlogContent = ({ content }) => {

  const sanitizedContent = DOMPurify.sanitize(content);
  useEffect(() => {
    // Add copy functionality to code blocks after render
    const codeBlocks = document.querySelectorAll('pre[data-code-block]');
    
    codeBlocks.forEach(block => {
      const code = block.textContent;
      const copyButton = document.createElement('button');
      copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>`;
      copyButton.className = 'absolute right-2 top-2 p-2 rounded bg-gray-700 hover:bg-gray-600 text-gray-300 opacity-0 group-hover:opacity-100 transition-opacity';
      
      copyButton.addEventListener('click', async () => {
        await navigator.clipboard.writeText(code);
        copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>`;
        setTimeout(() => {
          copyButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>`;
        }, 2000);
      });

      // Make the pre tag relative for absolute positioning of the button
      block.style.position = 'relative';
      block.classList.add('group');
      block.appendChild(copyButton);
    });
  }, [content]);

  return (
    <div 
      className="prose prose-lg max-w-none"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default BlogContent;