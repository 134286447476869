import { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import NavLink from './NavLink';
import MenuButton from './MenuButton';
import MobileMenu from './MobileMenu';

const navLinks = [
  { to: 'blog', label: 'blog' },
  { to: 'contact', label: 'contact' },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageError, setImageError] = useState(false);  // Track image load error

  const toggleMenu = () => setIsOpen(!isOpen);

  // Fallback function for image error
  const handleImageError = () => {
    setImageError(true); // Set error state to true when image fails to load
  };

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className={`${
        isOpen ? 'bg-zinc-800/80 backdrop-blur-sm' : 'bg-zinc-800'
      } text-white shadow-md sticky top-0 z-50 transition-colors duration-300 cursor-custom`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-5">
        <div className="relative flex items-center justify-between h-16">
          {/* Brand name */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="cursor-default"
          >
            <Link
              to="home"
              className="flex-shrink-0 lg:text-3xl text-xl font-semibold cursor-default"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {/* Display image or fallback text */}
              {!imageError ? (
                <img
                  src="/images/rupeshdon.png"
                  alt="Rupesh"
                  className="h-[3vh] w-[100%] object-contain"
                  srcSet="/images/rupeshdevkota@2x.png 2x, /images/rupeshdevkota@3x.png 3x"
                  onError={handleImageError}  // Handle error on image load failure
                />
              ) : (
                <span className="text-white bg-black py-1 px-3 rounded-lg">rupesh</span> // Display text when image fails
              )}
            </Link>
          </motion.div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <MenuButton isOpen={isOpen} onClick={toggleMenu} />
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex md:items-center md:space-x-4 cursor-default">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                label={link.label}
                className="cursor-default" // Apply cursor-custom for each NavLink
              />
            ))}
          </div>
        </div>

        {/* Mobile menu */}
        <MobileMenu
          isOpen={isOpen}
          navLinks={navLinks}
          onLinkClick={() => setIsOpen(false)}
        />
      </div>
    </motion.nav>
  );
};

export default Navbar;
