import { motion } from 'framer-motion';
import { Instagram, Linkedin, Github } from 'lucide-react';

const socialLinks = [
  { Icon: Instagram, href: "https://www.instagram.com/rupesh_sec/", label: "Instagram" },
  { Icon: Linkedin, href: "https://www.linkedin.com/in/rupesh-sshhh/", label: "LinkedIn" },
  { Icon: Github, href: "https://github.com/rupesh-sshhh", label: "Github" }
];

const containerVariants = {
  hidden: { 
    opacity: 0,
    y: 20
  },


  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      staggerChildren: 0.1
    }
  }
};

const iconVariants = {
  hidden: { 
    opacity: 0,
    x: -20
  },
  visible: {
    opacity: 1,
    x: 0
  },
  hover: { 
    scale: 1.2,
    rotate: 5,
    transition: { duration: 0.2 }
  }
};

const SocialLinks = () => {
  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="flex lg:flex-col gap-4"
    >
      {socialLinks.map(({ Icon, href, label }) => (
        <motion.a
          key={label}
          href={href}
          className="w-8 h-8 text-white/80 hover:text-emerald-300 transition-colors"
          aria-label={label}
          variants={iconVariants}
          whileHover="hover"
          whileTap={{ scale: 0.9 }}
        >
          <Icon size={24} />
        </motion.a>
      ))}
    </motion.div>
  );
};

export default SocialLinks;
