import React from 'react';

export function ImageSection() {
  return (
    <div className="flex-1 h-full">
      <img 
        src="/images/rupeshhh.png" 
        alt="Gallery Image" 
        className="w-full h-full object-contain mr-4 rounded-sm"
      />
    </div>
  );
}