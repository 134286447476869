import React from "react";
import { motion } from 'framer-motion';

export function PageHeader() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="text-center mb-12"
    >
      <h1 className="text-4xl font-bold text-white mb-4">Skills</h1>
      <p className="text-lg text-gray-400">Some random skills I’ve picked up:</p>
    </motion.div>
  );
}
