import React from 'react';
import { motion } from 'framer-motion';

const BlogHeader = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className="text-center max-w-3xl mx-auto mb-12"
    >
      <h2 className="text-4xl  font-bold mb-4"> Read My BLOGS</h2>
      <p className="text-gray-600">
      My blogs primarily revolve around cybersecurity, covering specific bugs and detailed write-ups of my learning experiences on various topics. FYI - I'm not an expert in the field—just sharing insights as I continue to learn.






</p>
    </motion.div>
  );
};

export default BlogHeader; 