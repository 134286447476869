import React from 'react';

const Served = () => {
  return (
    <>
    
    <div className=" flex flex-col  bg-opacity-95">
    <span
  className="text-white text-sm lg:-mb-9 mr-8 mb-2  flex items-center justify-center opacity-30 hover:opacity-50 transition-opacity duration-500"
>
  HELPED SECURE
</span>


      <div className="grid grid-cols-2 mt-[-3vh] lg:mt-0 md:mt-0 lg:flex lg:gap-10 sm:gap-4 items-center justify-center p-4 lg:p-8">
        {/* <div className="flex justify-center">
          <span className="text-gray-500 font-bold text-2xl hover:text-white transition-all duration-300">
            facebook
          </span>
        </div> */}

        <div className='flex  lg:mr-[-2vh] justify-center'>

        <svg
  className="fill-gray-500  fill-opacity-50  max-w-xs  hover:fill-white transition-all duration-300"
  version="1.0"
  xmlns="http://www.w3.org/2000/svg"
  width="175.000000pt"
  height="35.000000pt"
  viewBox="0 0 556.000000 70.000000"
  preserveAspectRatio="xMidYMid meet"
>
  <g
    transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)"
    stroke="none"
  >
    <path d="M268 864 c-54 -29 -58 -50 -58 -324 0 -361 -21 -340 340 -340 362 0
    340 -22 340 335 0 368 23 345 -337 345 -209 -1 -263 -4 -285 -16z m290 -114
    c4 -41 -21 -125 -38 -125 -11 0 -16 15 -18 64 -4 78 1 93 30 89 16 -2 24 -11
    26 -28z m105 -21 c7 -11 0 -31 -28 -72 -36 -54 -57 -68 -69 -48 -7 11 42 123
    57 133 15 10 28 5 40 -13z m82 -89 c0 -32 -101 -96 -123 -78 -12 10 -5 21 37
    61 55 52 86 59 86 17z m-177 -50 c119 -73 81 -259 -57 -277 -79 -11 -151 51
    -158 136 -10 119 115 202 215 141z"/>
    <path d="M457 502 c-23 -25 -21 -58 3 -82 24 -24 38 -25 68 -4 42 29 18 104
    -35 104 -11 0 -27 -8 -36 -18z"/>
    <path d="M3147 803 c-4 -3 -7 -41 -7 -84 0 -58 4 -78 15 -82 19 -8 22 -1 38
    76 9 40 11 71 5 81 -9 16 -39 22 -51 9z"/>
    <path d="M3274 758 c-5 -7 -20 -38 -35 -68 -26 -56 -28 -80 -4 -80 19 0 96
    111 93 135 -3 23 -42 33 -54 13z"/>
    <path d="M1150 495 l0 -195 70 0 70 0 0 114 c0 100 2 116 21 140 15 20 29 26
    48 24 l26 -3 3 -137 3 -138 69 0 70 0 0 129 c0 123 -1 131 -24 158 -22 25 -31
    28 -84 28 -38 -1 -68 -7 -85 -18 -41 -27 -47 -21 -47 38 l0 55 -70 0 -70 0 0
    -195z"/>
    <path d="M3326 633 c-60 -51 -63 -55 -43 -71 9 -7 31 1 80 29 67 40 78 54 55
    77 -20 20 -33 14 -92 -35z"/>
    <path d="M3500 485 l0 -195 70 0 c57 0 70 3 70 16 0 9 3 14 8 12 4 -2 23 -12
    43 -22 92 -47 199 37 199 156 -1 114 -119 193 -214 143 -15 -8 -30 -15 -32
    -15 -2 0 -4 23 -4 50 l0 50 -70 0 -70 0 0 -195z m249 66 c44 -44 34 -171 -16
    -210 -32 -26 -48 -26 -73 -1 -17 17 -20 33 -20 103 0 97 14 127 61 127 16 0
    38 -9 48 -19z"/>
    <path d="M2233 607 c-17 -6 -35 -15 -39 -19 -13 -14 -34 -9 -28 7 5 13 -6 15
    -70 15 l-76 0 0 -155 0 -155 73 0 72 0 0 119 c0 104 2 122 19 140 13 14 29 21
    45 19 l26 -3 3 -137 3 -138 69 0 70 0 0 114 c0 100 2 116 21 140 15 20 29 26
    48 24 l26 -3 3 -137 3 -138 69 0 70 0 0 131 0 131 -29 29 c-38 38 -97 39 -166
    4 l-49 -24 -23 20 c-26 26 -95 34 -140 16z"/>
    <path d="M2914 601 c-18 -11 -35 -24 -38 -30 -12 -19 -26 -12 -26 14 0 24 -2
    25 -70 25 l-70 0 0 -155 0 -155 73 0 72 0 -3 87 c-6 141 22 198 96 196 46 -1
    56 5 41 23 -16 19 -38 18 -75 -5z"/>
    <path d="M3078 608 c-47 -12 -105 -75 -114 -122 -16 -87 37 -171 124 -197 115
    -34 234 83 202 200 -25 94 -116 146 -212 119z m97 -113 c14 -13 25 -33 25 -45
    0 -28 -43 -70 -71 -70 -30 0 -69 38 -69 67 0 30 42 73 70 73 12 0 32 -11 45
    -25z"/>
    <path d="M1664 596 c-35 -16 -48 -31 -39 -46 4 -6 20 1 40 16 19 14 52 28 73
    31 49 7 72 -14 72 -66 l0 -38 -72 4 c-65 4 -77 2 -108 -19 -64 -43 -63 -123 1
    -171 33 -24 96 -25 141 -3 38 19 38 19 38 1 0 -12 14 -15 70 -15 l70 0 0 126
    c0 71 -5 134 -11 146 -6 11 -26 26 -44 34 -44 18 -189 18 -231 0z m134 -128
    c7 -7 12 -38 12 -70 0 -62 -13 -78 -62 -78 -37 0 -59 22 -65 65 -7 54 24 95
    71 95 17 0 37 -5 44 -12z"/>
    <path d="M4010 594 c-39 -17 -54 -34 -40 -48 5 -5 22 2 40 18 25 20 42 26 80
    26 54 0 60 -7 60 -72 0 -35 -2 -39 -17 -32 -38 16 -111 16 -148 -1 -71 -34
    -84 -119 -24 -169 41 -35 85 -41 139 -20 32 13 45 14 48 6 2 -8 28 -12 73 -12
    l69 0 0 126 c0 69 -4 133 -10 143 -24 47 -193 68 -270 35z m121 -124 c15 -9
    19 -22 19 -69 0 -69 -12 -84 -67 -79 -31 2 -41 9 -52 32 -34 73 33 151 100
    116z"/>
    <path d="M4366 601 c-8 -13 -10 -13 82 -11 39 1 72 -3 72 -8 0 -5 -42 -73 -94
    -151 l-93 -141 183 0 c111 0 184 4 184 10 0 5 -40 11 -89 12 l-89 3 94 143 93
    142 -72 0 c-40 0 -115 3 -168 6 -67 5 -98 3 -103 -5z"/>
    <path d="M4820 594 c-39 -17 -54 -34 -40 -48 5 -5 22 2 40 18 25 20 42 26 80
    26 54 0 60 -7 60 -71 0 -37 -1 -38 -26 -29 -40 16 -102 12 -143 -9 -69 -36
    -79 -116 -20 -165 41 -35 86 -41 139 -20 32 13 45 14 48 6 2 -8 28 -12 73 -12
    l69 0 0 129 c0 144 -6 159 -69 180 -48 16 -169 13 -211 -5z m121 -124 c15 -9
    19 -22 19 -69 0 -69 -12 -84 -67 -79 -31 2 -41 9 -52 32 -34 73 33 151 100
    116z"/>
    <path d="M5385 599 c-11 -7 -30 -21 -42 -32 l-23 -20 0 26 c0 27 0 27 -75 27
    l-75 0 0 -155 0 -155 75 0 75 0 0 106 c0 81 4 113 17 134 21 36 54 51 94 43
    27 -5 31 -3 27 13 -4 22 -44 29 -73 13z"/>
  </g>
</svg>
</div>
<div className='flex justify-center '>
{/* AYUH TECH */}
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="115.000000pt"
  height="50.000000pt"
 
 viewBox="0 0 534.000000 155.000000"
 preserveAspectRatio="xMidYMid meet"
 className="fill-gray-500 fill-opacity-50 mt-5  hover:fill-white transition-all duration-300"

 
 >

<g
    transform="translate(0.000000,109.000000) scale(0.100000,-0.100000)"
    stroke="none"
  >
<path d="M990 1474 c-156 -32 -189 -43 -190 -65 0 -4 38 -30 85 -58 47 -29 85
-57 85 -61 0 -5 -14 -24 -31 -41 -144 -147 -47 -372 161 -374 51 0 77 6 115
25 85 44 135 143 116 230 -11 52 4 86 41 94 49 10 75 7 81 -8 3 -7 3 -61 1
-119 -6 -109 3 -147 31 -147 17 0 20 16 5 25 -5 3 -10 18 -10 32 l0 25 19 -23
c25 -31 40 -16 19 19 -19 32 -24 69 -14 99 4 13 4 28 -1 35 -4 7 -13 28 -19
46 l-12 32 44 11 c30 8 44 17 44 28 -1 22 -325 225 -365 228 -16 1 -109 -14
-205 -33z m218 -112 c3 -9 -3 -13 -19 -10 -12 1 -24 9 -27 16 -3 9 3 13 19 10
12 -1 24 -9 27 -16z"/>
<path d="M2815 1053 c0 -104 -3 -203 -7 -220 l-7 -33 70 0 70 0 -8 68 c-13
110 -10 159 10 179 17 17 19 17 34 -2 12 -16 14 -42 10 -137 l-5 -118 64 0 64
0 -6 123 c-7 164 -10 180 -41 211 -29 30 -78 35 -109 11 -30 -23 -32 -19 -27
45 l6 60 -59 0 -59 0 0 -187z"/>
<path d="M1880 1175 c0 -56 -26 -200 -54 -295 l-23 -80 78 0 78 0 -5 48 c-4
34 -1 52 10 67 16 20 16 20 37 -5 17 -19 20 -34 16 -67 l-5 -43 79 0 c44 0 79
3 79 6 0 4 -13 48 -29 98 -33 104 -61 230 -61 278 l0 33 -100 0 -100 0 0 -40z
m118 -122 c10 -31 9 -40 -3 -52 -24 -24 -38 0 -30 48 8 51 18 52 33 4z"/>
<path d="M2340 1124 c0 -1 5 -37 11 -80 12 -90 7 -124 -21 -124 -31 0 -43 36
-36 106 4 35 9 71 12 79 5 12 -5 15 -58 15 l-64 0 4 -112 c5 -124 18 -163 58
-178 38 -15 50 -12 85 17 l32 28 -7 -45 c-9 -56 -30 -90 -56 -90 -22 0 -27 25
-7 34 16 7 -20 36 -45 36 -29 0 -68 -45 -68 -78 0 -86 151 -109 223 -34 42 44
55 88 61 222 4 69 9 143 12 165 l4 40 -70 1 c-38 1 -70 0 -70 -2z"/>
<path d="M2666 1046 c8 -93 1 -136 -21 -136 -22 0 -29 42 -21 131 l6 79 -64 0
c-48 0 -64 -3 -61 -12 3 -7 9 -68 13 -135 8 -109 11 -126 32 -148 65 -69 186
-38 209 54 5 20 11 84 14 144 l5 107 -59 0 -59 0 6 -84z"/>
<path d="M505 903 c-65 -9 -158 -47 -213 -87 -131 -97 -195 -245 -171 -399 37
-234 276 -398 520 -357 97 17 194 67 258 135 160 169 157 414 -7 573 -109 107
-244 154 -387 135z m162 -71 c22 -9 28 -32 9 -32 -9 0 -12 -7 -9 -20 3 -11 12
-20 19 -20 8 0 14 -4 14 -10 0 -5 -5 -10 -11 -10 -6 0 -9 -7 -5 -15 3 -9 0
-15 -10 -15 -8 0 -19 10 -24 23 -6 16 -9 17 -9 5 -1 -9 3 -19 9 -23 5 -3 7
-12 4 -19 -4 -10 -17 -12 -42 -9 -32 4 -41 0 -67 -27 -29 -31 -30 -32 -10 -46
18 -13 21 -12 36 17 16 31 16 32 22 8 8 -28 -29 -67 -46 -50 -7 7 -18 3 -35
-9 -14 -11 -31 -20 -38 -20 -8 0 -11 -6 -8 -15 4 -10 0 -15 -14 -15 -26 0 -43
-29 -32 -50 6 -12 -2 -23 -35 -45 -55 -38 -69 -88 -36 -134 19 -26 81 -50 81
-30 0 4 18 0 40 -8 22 -9 40 -22 40 -30 0 -7 8 -26 18 -40 9 -15 17 -40 17
-56 0 -28 -1 -29 -37 -23 -143 23 -260 116 -308 244 -31 83 -26 195 14 276 18
36 31 54 36 46 5 -8 9 -4 14 12 5 19 8 20 15 8 6 -9 6 -19 -1 -27 -7 -8 -7
-13 0 -13 20 0 76 95 66 112 -6 9 36 31 44 23 4 -4 -2 -13 -12 -21 -13 -9 -15
-14 -6 -14 21 0 49 30 43 46 -3 8 0 14 6 14 6 0 11 -5 11 -11 0 -5 4 -8 9 -4
5 3 13 0 17 -6 7 -11 -37 -59 -54 -59 -15 0 -34 -28 -24 -34 38 -23 168 58
141 90 -6 8 -20 14 -31 14 -27 0 -18 23 13 32 27 8 139 2 166 -10z m27 -300
c3 -5 -11 -16 -30 -25 -36 -18 -38 -37 -3 -37 12 0 19 -7 19 -19 0 -17 -7 -19
-66 -19 -52 0 -70 4 -86 20 -16 16 -27 18 -55 13 l-36 -6 34 30 c18 17 36 31
40 31 3 0 18 -11 33 -25 25 -23 39 -12 14 13 -6 6 -9 14 -6 17 3 4 16 -8 28
-25 l22 -31 25 35 c22 34 55 47 67 28z m257 -107 c-15 -78 -47 -141 -102 -197
-44 -44 -150 -110 -121 -75 8 9 11 25 8 35 -4 13 2 30 17 48 45 54 61 102 32
90 -24 -9 -58 12 -83 51 -12 20 -21 37 -19 39 7 7 77 -64 77 -78 1 -21 70 60
70 82 0 21 -15 27 -25 10 -5 -8 -11 -7 -21 1 -22 18 -17 24 24 25 20 1 50 5
66 9 22 6 31 4 38 -9 15 -26 28 -19 28 16 0 23 3 29 11 21 7 -7 7 -27 0 -68z"/>
<path d="M1017 816 c-27 -8 -51 -17 -54 -20 -3 -3 6 -21 21 -41 99 -129 120
-313 52 -461 l-25 -55 199 3 200 3 31 31 c117 117 -14 451 -206 523 -74 28
-157 34 -218 17z"/>
<path d="M4566 678 c-21 -30 -20 -54 4 -78 29 -29 74 -26 99 7 36 45 7 93 -55
93 -23 0 -38 -7 -48 -22z"/>
<path d="M2754 488 c1 -101 -2 -201 -6 -223 l-7 -40 70 3 c67 4 70 5 66 25
-15 62 -19 168 -7 197 14 34 35 39 50 11 12 -23 12 -125 1 -194 l-8 -47 67 0
67 0 -4 128 c-4 104 -9 135 -26 172 -29 59 -69 73 -123 41 l-37 -22 7 61 7 60
-45 0 c-24 0 -51 2 -58 5 -12 5 -14 -24 -14 -177z"/>
<path d="M3737 644 c9 -37 9 -315 0 -374 l-8 -45 70 3 69 4 -6 36 c-4 20 -7
117 -7 215 l0 177 -47 0 c-25 0 -54 3 -63 6 -13 6 -15 2 -8 -22z"/>
<path d="M1820 537 l0 -98 51 53 50 53 -5 -120 c-3 -66 -8 -138 -12 -160 l-6
-40 81 3 c71 3 81 6 77 20 -25 85 -36 302 -16 302 6 0 31 -28 56 -62 l47 -63
-6 55 c-4 30 -7 78 -7 106 l0 52 -155 -2 -155 -1 0 -98z"/>
<path d="M3243 560 c-27 -16 -33 -17 -33 -5 0 12 -14 15 -62 15 l-63 0 0 -170
-1 -170 69 0 c67 0 69 1 63 23 -4 12 -9 60 -13 107 -5 77 -3 87 16 110 21 25
21 25 37 5 13 -16 15 -36 10 -106 -3 -48 -10 -99 -16 -114 -6 -15 -9 -29 -8
-30 2 -1 35 0 74 3 l71 4 -4 107 c-5 157 -11 190 -38 217 -29 29 -60 30 -102
4z"/>
<path d="M2277 556 c-49 -18 -72 -39 -97 -90 -36 -75 -22 -185 29 -230 30 -27
118 -33 159 -12 37 19 62 62 62 105 0 38 -12 45 -33 20 -22 -25 -78 -25 -100
-1 -24 26 -22 29 23 35 55 7 86 29 100 72 16 49 -1 90 -42 104 -39 14 -54 13
-101 -3z m68 -77 c9 -14 -24 -49 -46 -49 -21 0 -16 27 9 48 19 15 29 15 37 1z"/>
<path d="M2553 551 c-107 -50 -136 -240 -46 -311 82 -64 203 -8 203 94 0 55
-6 59 -35 30 -29 -29 -58 -31 -85 -4 -26 26 -26 80 1 114 23 29 49 35 49 10 0
-10 -6 -14 -15 -10 -19 7 -21 -44 -3 -62 37 -37 105 17 94 73 -15 70 -89 101
-163 66z"/>
<path d="M3525 563 c-64 -17 -106 -83 -107 -167 -1 -114 46 -176 133 -176 66
0 104 21 131 73 28 56 30 150 2 197 -34 59 -100 89 -159 73z m65 -109 c17 -42
12 -100 -10 -125 l-20 -24 -20 24 c-22 26 -26 72 -9 120 14 39 45 42 59 5z"/>
<path d="M3985 556 c-84 -38 -120 -164 -76 -263 24 -51 63 -73 129 -73 84 0
141 70 142 172 0 126 -98 209 -195 164z m72 -88 c29 -37 22 -132 -11 -152 -26
-16 -46 17 -46 77 0 73 29 110 57 75z"/>
<path d="M4274 551 c-80 -49 -87 -236 -11 -286 41 -26 87 -23 114 9 23 27 23
27 23 4 0 -43 -21 -98 -42 -109 -26 -14 -47 3 -30 24 16 19 -4 37 -40 37 -59
0 -89 -72 -49 -121 15 -19 30 -24 82 -27 54 -3 69 0 99 20 58 38 77 90 84 226
3 64 8 142 11 174 l5 56 -65 4 c-58 3 -65 1 -65 -15 0 -17 -2 -17 -26 2 -33
26 -50 26 -90 2z m111 -94 c4 -13 5 -43 3 -68 -2 -36 -7 -45 -24 -47 -23 -3
-34 19 -34 71 0 58 43 93 55 44z"/>
<path d="M4839 561 c-48 -16 -78 -43 -104 -94 -30 -62 -32 -125 -6 -184 26
-56 67 -77 139 -71 47 4 60 10 84 37 22 24 28 41 28 76 0 25 -2 45 -5 45 -3 0
-14 -9 -25 -20 -33 -33 -110 -23 -110 16 0 6 18 14 39 18 56 9 91 42 98 91 4
30 0 46 -13 62 -21 25 -84 37 -125 24z m58 -88 c6 -16 -25 -43 -48 -43 -17 0
-18 3 -8 23 19 36 48 46 56 20z"/>
<path d="M5083 559 c-43 -13 -73 -53 -73 -99 0 -42 17 -61 88 -100 52 -28 71
-70 33 -70 -22 0 -30 30 -8 30 28 1 -30 30 -60 30 -68 0 -80 -75 -19 -115 87
-57 226 -2 226 89 0 45 -19 66 -91 101 -53 25 -64 34 -64 55 0 18 6 26 23 28
23 4 33 -23 11 -30 -21 -7 21 -38 50 -38 60 0 79 71 28 105 -30 19 -103 27
-144 14z"/>
<path d="M4556 534 c9 -37 10 -200 1 -259 l-7 -50 70 3 69 4 -7 31 c-4 18 -7
91 -7 162 l0 130 -63 3 -62 2 6 -26z"/>
</g>
</svg>

{/* handlemypaprer */}
</div>

<div className='ml-28 lg:ml-0 md:ml-0 sm:ml-0'>
        <svg
  className="fill-gray-500  fill-opacity-50 sm:hover:fill-white sm:transition-all hover:fill-white transition-all duration-300"
  version="1.0"
  xmlns="http://www.w3.org/2000/svg"
  width="175.000000pt"
  height="50.000000pt"
  viewBox="0 0 556.000000 140.000000"
  preserveAspectRatio="xMidYMid meet"
>
  <g
    transform="translate(0.000000,109.000000) scale(0.100000,-0.100000)"
    stroke="none"
  >
<path d="M124 916 l-34 -34 0 -382 0 -382 34 -34 35 -35 360 3 361 3 27 28 28
27 0 390 0 390 -28 27 -27 28 -361 3 -360 3 -35 -35z m406 -261 c-14 -50 -19
-103 -18 -185 l1 -115 8 104 c4 57 10 109 13 116 2 7 29 22 58 33 105 39 103
39 58 -20 -48 -62 -96 -158 -105 -210 -4 -21 -12 -38 -17 -38 -7 0 -8 -16 -5
-41 3 -23 2 -38 -3 -33 -54 58 -218 270 -213 275 22 21 236 189 238 187 2 -2
-5 -34 -15 -73z m133 -222 c-33 -32 -72 -79 -87 -105 -15 -27 -30 -48 -32 -48
-7 0 8 82 23 125 26 76 37 85 99 85 l56 0 -59 -57z"/>
<path d="M1150 560 c0 -153 1 -160 20 -160 18 0 20 7 20 68 0 72 16 102 53
102 33 0 47 -29 47 -101 0 -62 2 -69 20 -69 18 0 20 7 20 74 0 59 -4 78 -21
100 -22 28 -64 35 -100 16 -18 -10 -19 -7 -19 60 0 63 -2 70 -20 70 -19 0 -20
-7 -20 -160z"/>
<path d="M2010 650 c0 -67 -1 -70 -19 -60 -34 17 -88 12 -116 -12 -31 -28 -43
-85 -25 -128 22 -54 103 -78 142 -43 11 9 18 11 18 5 0 -7 9 -12 20 -12 19 0
20 7 20 160 0 153 -1 160 -20 160 -18 0 -20 -7 -20 -70z m-13 -102 c18 -15 16
-83 -3 -102 -26 -27 -71 -24 -96 6 -56 65 33 151 99 96z"/>
<path d="M2120 560 c0 -136 2 -160 15 -160 13 0 15 24 15 160 0 136 -2 160
-15 160 -13 0 -15 -24 -15 -160z"/>
<path d="M1412 586 c-20 -11 -21 -14 -9 -25 10 -8 17 -9 22 -1 11 18 73 12 85
-9 15 -28 15 -28 -40 -33 -59 -6 -90 -30 -90 -69 0 -32 32 -59 70 -59 14 0 36
7 48 16 12 8 22 10 22 5 0 -6 7 -11 15 -11 12 0 15 16 15 84 0 76 -2 86 -22
100 -27 19 -84 20 -116 2z m108 -109 c0 -7 -7 -23 -16 -35 -22 -32 -78 -28
-82 7 -2 13 2 28 10 32 21 14 88 11 88 -4z"/>
<path d="M1610 500 c0 -93 1 -100 20 -100 18 0 20 7 20 70 0 63 2 71 25 86 21
14 31 15 50 6 23 -11 25 -17 25 -87 0 -70 2 -75 21 -75 20 0 21 4 18 86 -3 71
-7 88 -23 100 -23 16 -69 18 -97 4 -13 -7 -19 -7 -19 0 0 5 -9 10 -20 10 -19
0 -20 -7 -20 -100z"/>
<path d="M2242 575 c-38 -32 -48 -72 -29 -116 19 -46 39 -61 90 -66 33 -4 48
0 67 15 35 29 15 50 -26 26 -34 -19 -76 -8 -94 26 -10 19 -8 20 75 20 94 0 97
2 71 65 -26 61 -100 75 -154 30z m112 -27 c22 -31 12 -38 -50 -38 -40 0 -54 4
-54 14 0 45 77 62 104 24z"/>
<path d="M2577 593 c-4 -3 -7 -48 -7 -100 0 -86 1 -93 20 -93 18 0 20 7 20 65
0 52 4 69 20 85 47 47 80 14 80 -81 0 -62 2 -69 20 -69 18 0 20 7 20 75 0 71
1 76 26 87 45 21 59 2 62 -86 3 -68 5 -76 22 -76 18 0 20 7 20 71 0 68 -11
106 -34 121 -20 13 -69 8 -88 -9 -17 -15 -19 -15 -36 0 -10 10 -31 17 -47 17
-16 0 -37 -7 -47 -17 -17 -15 -18 -15 -18 0 0 17 -21 23 -33 10z"/>
<path d="M2920 594 c0 -3 18 -50 41 -104 37 -91 39 -102 27 -127 -9 -19 -22
-29 -41 -31 -20 -3 -27 -8 -25 -20 5 -25 54 -27 74 -3 13 15 124 273 124 288
0 2 -9 3 -19 3 -15 0 -26 -17 -48 -75 -15 -41 -30 -75 -33 -75 -3 0 -18 34
-33 75 -22 58 -33 75 -48 75 -10 0 -19 -3 -19 -6z"/>
<path d="M3274 587 c-2 -7 -3 -80 -2 -162 3 -133 5 -150 21 -153 15 -3 17 6
17 73 l0 76 31 -17 c84 -43 170 38 139 131 -19 58 -98 84 -148 49 -20 -14 -22
-14 -22 0 0 19 -29 21 -36 3z m153 -43 c20 -20 24 -31 19 -56 -9 -45 -24 -58
-66 -58 -42 0 -57 13 -66 58 -5 25 -1 36 19 56 15 14 36 26 47 26 12 0 32 -12
47 -26z"/>
<path d="M3562 586 c-19 -10 -21 -15 -10 -24 9 -7 19 -8 28 -2 26 17 69 11 80
-9 15 -28 15 -28 -40 -33 -59 -6 -90 -30 -90 -69 0 -32 32 -59 70 -59 14 0 36
7 48 16 12 8 22 10 22 5 0 -6 7 -11 15 -11 12 0 15 16 15 84 0 76 -2 86 -22
100 -27 19 -84 20 -116 2z m108 -109 c0 -7 -7 -23 -16 -35 -12 -17 -24 -22
-47 -20 -35 3 -47 29 -27 53 16 19 90 20 90 2z"/>
<path d="M3760 435 c0 -158 1 -165 20 -165 18 0 20 7 20 76 l0 75 22 -15 c28
-20 61 -20 98 -1 37 19 50 43 50 95 0 76 -83 126 -151 90 -13 -7 -19 -7 -19 0
0 5 -9 10 -20 10 -19 0 -20 -7 -20 -165z m158 108 c19 -25 15 -77 -8 -98 -43
-39 -110 -9 -110 49 0 17 4 36 8 42 24 35 85 39 110 7z"/>
<path d="M4058 585 c-31 -18 -52 -73 -43 -115 9 -43 58 -80 105 -80 45 0 92
32 73 50 -9 10 -17 9 -34 -4 -13 -9 -31 -16 -40 -16 -19 0 -69 37 -69 51 0 5
36 9 80 9 l80 0 0 35 c0 71 -82 108 -152 70z m100 -27 c7 -7 12 -20 12 -30 0
-15 -9 -18 -60 -18 -33 0 -60 3 -60 8 0 4 7 18 16 30 17 25 71 31 92 10z"/>
<path d="M4260 500 c0 -93 1 -100 20 -100 18 0 20 7 20 64 0 71 14 96 56 96
17 0 24 5 24 20 0 25 -36 27 -62 3 -17 -15 -18 -15 -18 0 0 10 -8 17 -20 17
-19 0 -20 -7 -20 -100z"/>
</g>
</svg>
</div>





      </div>
    </div>
    </>

  );
};

export default Served;
