import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Navbar from "./components/main/Navbar";
import Hero from "./components/main/Hero";
import About from "./components/About/About";
import Blog from "./components/Blog/BlogSection";
import SingleBlogPost from "./components/Blog/SIngleBlog";
import Twitter from "./components/Twitter/Twitter";
import Skills from "./components/Skills/Skills";
import CertificationsSection from './components/Certifications/CertificationsSection';
import Contact from './components/Footer/Contact';
import Served from './components/Served/Served';
// index.tsx or App.tsx
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import Navigation from './components/main/Navigation';


const skills = [
  // your skills array here
  {
    title: 'Vendor Security Assessments',
    company: 'SecurityPal',
    proficiency: 'Expert',
  },
  {
    title: 'Security due diligence reviews',
    company: 'SecurityPal',
    proficiency: 'Expert',
  },
  {
    title: 'CTF',
    company: 'Coventry University',
    proficiency: 'Intermediate',
  },

  {
    title: 'Network Security',
    company: 'Coventry University',
    proficiency: 'Intermediate',
  },


  {
    title: 'Security  Principles',
    company: 'ISC2 CC',
    proficiency: 'Advanced',
  },
  {
    title: 'Security  Operations',
    company: 'ISC2 CC',
    proficiency: 'Advanced',
  },
  {
    title: 'Programming / Python',
    company: 'Coventry University',
    proficiency: 'Intermediate',
  },
  {
    title: 'Programming / Golang',
    company: 'Self',
    proficiency: 'Beginner',
  },
  {
    title: 'Digital Forensics',
    company: 'Coventry University',
    proficiency: 'Intermediate',
  },
  {
    title: 'Bug Bounty',
    company: 'Self',
    proficiency: 'Beginner',
  },


];

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // State to track if scrolling to blog section has been done already
  const [scrolledToBlog, setScrolledToBlog] = useState(false);

  useEffect(() => {
    // Prevent scroll to blog when on single blog post page or already scrolled
    if (location.pathname === "/" && location.state?.scrollTo === 'blog' && !scrolledToBlog) {
      const blogSection = document.getElementById('blog');
      if (blogSection) {
        blogSection.scrollIntoView({ behavior: 'smooth' });
        setScrolledToBlog(true); // Mark as scrolled to blog
      }
    }
  }, [location, scrolledToBlog]); // Dependencies to handle navigation

  useEffect(() => {
    // Reset scroll state when navigating away from the blog section or single blog post
    if (location.pathname !== "/") {
      setScrolledToBlog(false);
    }
  }, [location]);

  useEffect(() => {
    // Redirect to home if the user directly accesses /blog (since we're using anchor navigation)
    if (location.pathname === "/blog") {
      navigate("/", { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className="bg-black">
      <Navbar />
      {/* <Navigation/> */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div id="home">
                <Hero />
              </div>
              <div id="served">
                <Served />
              </div>
              <div id="about">
                <About />
              </div>
              <div id="blog">
                <Blog />
              </div>
              <div id="skills">
                <Skills skills={skills} />
              </div>
              <div id="certifications">
                <CertificationsSection />
              </div>
              <div id="twitter">
                <Twitter />
              </div>
              <div id="contact">
                <Contact />
              </div>
            </>
          }
        />
        <Route path="/bloglist/:id" element={<SingleBlogPost />} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Catch-all route to redirect unmatched paths */}
      </Routes>
    </div>
  );
}
