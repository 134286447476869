import { motion } from 'framer-motion';
import { CertificationCard } from './CertficationCard';
import React, { useState } from 'react';

const certifications = [
  {
    title: "Certified in Cybersecurity (CC)",
    organization: "ISC2",
    issueDate: "July 26,2023",
    expiryDate: "February 13, 2024",
    imageUrl: "/images/ccexam.png",
    credentialUrl: "https://www.credly.com/badges/431673f7-1c59-4266-8720-476cdb246d1c/public_url"
  },

  {
    title: "Advent Of Cyber 2024",
    organization: "ISC2",
    issueDate: "Dec 31, 2024",
    expiryDate: "Jul 2026",
    imageUrl: "/images/advent.png",
    credentialUrl: ""
  },

  {
    title: "Jr Penetration Tester",
    organization: "ISC2",
    issueDate: "Aug 10, 2022",
    expiryDate: "Jul 2026",
    imageUrl: "/images/jrpenetrationtest.png",
    credentialUrl: ""
  },

  // Add more certification objects here
];

export default function CertificationsSection({ id }: { id: string }) {
  const [showAll, setShowAll] = useState(false); // State to toggle between show more and show less

  // Handle the Show More/Show Less button click
  const handleToggle = () => {
    setShowAll(prevState => !prevState); // Toggle the state
  };

  return (
    <section id={id} className="py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Certifications & Trainings</h2>
          <p className="text-lg text-gray-400">

"eJPT is on my roadmap for the first half of 2025, followed by CompTIA Security+ and PenTest+."</p>
        </motion.div>

        {/* Certification Cards */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {certifications.slice(0, showAll ? certifications.length : 3).map((cert, index) => (
            <CertificationCard
              key={index}
              {...cert}
              delay={index * 0.2}
            />
          ))}
        </div>

        {/* Show More/Show Less Button */}
        {/* <div className="flex justify-center mt-6">
          <button
            onClick={handleToggle}
            className="bg-emerald-300 text-black px-4 py-2 rounded-full hover:bg-emerald-400 transition-colors duration-300"
          >
            {showAll ? 'Show Less' : 'Show More'}
          </button>
        </div> */}
      </div>
    </section>
  );
}
