import { Tweet } from 'react-tweet';

const Twitter = () => {

  const TwitterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
    </svg>
  );
  
  return (
    <>
      <div>
        <section className="bg-black text-white shadow-lg p-8">
        <div className="flex items-center gap-3 mb-6">
            <a 
              href="https://twitter.com/Rupesh123" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white text-3xl font-bold hover:underline flex items-center space-x-2"  // Add space-x-2 for horizontal spacing
            >
              <span>Rupesh On</span>
              <TwitterIcon className="w-8 h-8 hover:scale-125  transition-colors" />
            </a>
          </div>

          <div className="grid m-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Tweet container with hover effect and spacing adjustments */}
            <div data-theme="dark" className="rounded-lg shadow-md p-4 hover:shadow-xl transition-shadow">
              <Tweet id="1871917136459034803" />
            </div>

            <div data-theme="dark" className="rounded-lg shadow-md p-4 hover:shadow-xl transition-shadow">
              <Tweet id="1873785155120398681" />
            </div>

            <div data-theme="dark" className="rounded-lg shadow-md p-4 hover:shadow-xl transition-shadow">
              <Tweet id="1873786554352898204" />
            </div>

            {/* Add more Tweet components as needed */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Twitter;
