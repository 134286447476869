import { motion } from 'framer-motion';

const ProfileImage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ 
        duration: 0.8,
        ease: "easeOut"
      }}
      whileHover={{ 
        scale: 1.05,
        transition: { duration: 0.2 }
      }}
      className="relative w-full max-w-xs"
    >
      <motion.div 
        className="aspect-square rounded-full overflow-hidden border-2 border-emerald-300/20 shadow-2xl"
        initial={{ borderColor: "rgba(110, 231, 183, 0.1)" }}
        animate={{ borderColor: "rgba(110, 231, 183, 0.2)" }}
        transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
      >
        <img
          src="/new.png"
          alt="Profile"
          className="w-full h-full object-cover transform transition-transform duration-700 hover:scale-110"
        />
      </motion.div>
    </motion.div>
  );
};

export default ProfileImage;