import React from 'react';
import { PageHeader } from './PageHeader';
import {SkillsSection} from './SkillsSection'

const Skills = ({ skills }) => {
  return (
    <section id="skills" className="h-content">
      <PageHeader />
      <SkillsSection skills={skills} /> {/* Pass skills here */}
    </section>
  );
}

export default Skills;
