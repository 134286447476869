import React from 'react';
import { Github, LinkedinIcon } from 'lucide-react';

export function ContactInfo() {
  return (
    <div className="space-y-6 text-lg">

      
      <div>
        <p>Kathmandu</p>
        <p>Bagmati, Nepal</p>
      </div>
      
      <div className="flex gap-4">
        <a href="https://github.com/rupesh-sshhh" className="hover:text-gray-300">
          <Github size={24} />
        </a>
        <a href="https://x.com/RupeshDevkota5" className="hover:text-gray-300" target="_blank" rel="noopener noreferrer">
          {/* Realistic X logo for Twitter */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
    </svg>
        </a>

        <a href="https://www.linkedin.com/in/rupesh-sshhh/" className="hover:text-gray-300" target="_blank" rel="noopener noreferrer">
          <LinkedinIcon size={24} />
        </a>
      </div>

      {/* Map Section */}

     



      {/* Phone Number Section */}
      <div className="mt-6">
        <p className="text-lg">
       
        </p>
      </div>


      {/* Copyright Section */}
      <div className="mt-4 text-start text-sm text-gray-700">
        <p>&copy; Rupesh 2024</p>
      </div>
    </div>
  );
}
