import React, { useState } from 'react';
import { Send, Mail } from 'lucide-react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

export function NewsletterSubscribe() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [fadeOut, setFadeOut] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post('https://portfolio-backend-y0el.onrender.com/api/blog/send-email', { email });
      setStatus('success');
      setEmail('');
      setTimeout(() => setFadeOut(true), 5000);
    } catch (error) {
      console.error('Error subscribing:', error);
      setStatus('error');
    }
  };

  return (
    <div className="w-full ml-6 flex flex-col justify-center lg:mt-1 items-center  max-w-[80%] space-y-2">
      
      {/* Header Section */}
      <div className="flex items-center gap-2 text-slate-100">
        <Mail className="w-5 h-5" />
        <h3 className="font-semibold text-center">Subscribe to Newsletter</h3>
      </div>

      {/* "Be the First to Know" section */}
      <div className="p-3 max-w-full text-white rounded-md text-center mb-4 break-words">
      Get notified whenever I post something new. Don’t worry, I’m allergic to spam too!
</div>

      {/* Form Section */}
      <div className="flex flex-col md:flex-row md:items-center md:gap-20">
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-2 w-full items-center">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="flex-1 px-4 py-3 w-[80vw] ml-14 lg:ml-48 sm:w-[60vw] md:w-[40vw] lg:w-[30vw] xl:w-[25vw] bg-white rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-emerald-300"
            required
          />
          <button
            type="submit"
            className="inline-flex items-center ml-5  justify-center gap-2 bg-emerald-300 text-black px-4 p-3 rounded-lg mt-2 sm:mt-0 hover:bg-emerald-100 transition-colors"
          >
            Subscribe
            <Send size={16} />
          </button>
        </form>
      </div>

      {/* Success Message */}
      <AnimatePresence>
        {status === 'success' && !fadeOut && (
          <motion.p
            className="text-sm text-emerald-300 text-center md:text-left"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            key="success-message"
          >
            Thanks for subscribing!
          </motion.p>
        )}
      </AnimatePresence>

      {/* Error Message */}
      {status === 'error' && (
        <p className="text-sm text-red-500 text-center md:text-left">Failed to subscribe. Please try again.</p>
      )}
    </div>
  );
}
