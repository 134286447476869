import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock } from 'lucide-react';
import { BlogPost } from './types';
import { Link } from 'react-router-dom';

interface BlogCardProps {
  content: string;
  title: string;
  image: string;
  dateOfPublished: string;
  delay: number;
  id: string;
  readingtime:string;
}

const BlogCard: React.FC<BlogCardProps> = ({ content, title,readingtime, image, dateOfPublished, delay, id }) => {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
      className="bg-gray-800 text-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="relative h-48 overflow-hidden">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
        />
        {/* <div className="absolute top-4 left-4">
          <span className="px-3 py-1 bg-black text-white text-sm rounded-full">
          </span>
        </div> */}
      </div>

      <div className="p-6">
        <h3 className="text-xl font-semibold mb-3 hover:text-gray-400 transition-colors duration-300">
          {title}
        </h3>
        
        <div className="flex items-center justify-between text-sm text-gray-400">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Calendar className="w-4 h-4" />
              <span>{dateOfPublished}</span>
            </div>
            <div className="flex items-center gap-1">
              <Clock className="w-4 h-4" />
              <span className=''>{readingtime}</span>
            </div>
          </div>
        </div>

        {/* Read More Button */}
        <Link
          to={`/bloglist/${id}`}
          className="inline-block mt-4 px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors duration-300"
        >
          Read More
        </Link>
      </div>
    </motion.article>
  );
};

export default BlogCard;
