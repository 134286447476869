import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, RotateCcw, Clock, Clipboard, Facebook, Linkedin, ArrowLeft } from 'lucide-react';
import DOMPurify from 'dompurify'; // For sanitizing HTML content
import axios from 'axios';
import { Helmet } from 'react-helmet';
import BlogContent from './BlogContent'; // Import BlogContent
import './styles/styles.css';

const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
  </svg>
);

const SingleBlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [singlepost, setSinglepost] = useState(null);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await axios.get(`https://portfolio-backend-y0el.onrender.com/api/blog/bloglist/${id}`, { withCredentials: true });
        console.log(res.data);
        setImage(res?.data?.images[0]);
        setSinglepost(res.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch blog post');
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const handleBackToBlog = () => {
    navigate('/', { state: { scrollTo: 'blog' } });
  };

  const handleCopy = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((err) => {
        setCopySuccess(false);
        console.error("Failed to copy URL:", err);
      });
  };

  const handleShare = (platform) => {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const title = encodeURIComponent(singlepost?.title || '');
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${title}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodedUrl}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return <div className="text-center text-xl">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 text-xl">{error}</div>;
  }

  if (!singlepost) {
    return <div className="text-center text-xl">No blog post found</div>;
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={singlepost?.title || "Default Title"} />
        <meta property="og:description" content={singlepost?.content || 'Default description'} />
        <meta property="og:image" content={image || 'https://yourwebsite.com/default-image.jpg'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <motion.article
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen bg-white text-gray-900"
      >
        {/* Header Section */}
        <div className="max-w-7xl mx-auto p-8 mt-10 bg-white shadow-2xl rounded-lg border border-gray-200">
          <div className="flex justify-between items-center mb-6">
            <button
              onClick={handleBackToBlog}
              className="text-gray-700 bg-gray-200 px-4 py-2 rounded-md flex items-center gap-2 transition-all duration-300 hover:bg-gray-300"
            >
              <ArrowLeft className="w-4 h-4" />
              Back to Blog
            </button>

            {/* Social Share Icons */}
            <div className="flex gap-4">
              <button onClick={() => handleShare('facebook')} className="hover:scale-150 delay-200 duration-150">
                <Facebook className="w-5 h-5" />
              </button>
              <button onClick={() => handleShare('linkedin')} className="hover:scale-150 delay-200 duration-150">
                <Linkedin className="w-5 h-5" />
              </button>
              <button onClick={() => handleShare('twitter')} className="hover:scale-150 delay-200 duration-150">
                <TwitterIcon className="w-5 h-5" />
              </button>
            </div>

            {/* Copy URL Button */}
            <button
              onClick={handleCopy}
              className="p-2 bg-gray-400 text-white rounded-full transition-all duration-300 hover:bg-gray-500"
            >
              {copySuccess ? "Copied!" : <Clipboard className="w-5 h-5" />}
            </button>
          </div>

          {/* Image Section */}
          {image && (
            <div className="relative mb-6">
              <img
                src={image}
                alt={singlepost.title}
                className="w-full h-72 object-cover rounded-lg shadow-md custom-image"
                onError={(e) => {
                  e.currentTarget.src = 'https://via.placeholder.com/800x400'; // Fallback image
                }}
              />
            </div>
          )}

          {/* Content Section */}
          <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
            <h1 className="text-3xl font-extrabold text-gray-900 mb-4">{singlepost.title}</h1>

            {/* Enhanced Reading Time Section */}
            <div className="flex items-center gap-2 mb-4 text-lg font-semibold text-gray-800 bg-gray-100 p-3 rounded-lg shadow-md">
              <Clock className="w-5 h-5 text-gray-600" />
              <span>{singlepost.readingtime} read</span>
            </div>
            <div className='flex gap-4'>
              <p className="text-gray-600 mb-4">
                <Calendar className="inline-block w-4 h-4 mr-1" />
                {new Date(singlepost.createdAt).toLocaleDateString()}
              </p>
              <p className="text-gray-600 mb-4">
                <RotateCcw className="inline-block w-4 h-4 mr-1" />
                {new Date(singlepost.updatedAt).toLocaleDateString()}
              </p>
            </div>
            <BlogContent content={singlepost.content} />
          </div>
        </div>

        {/* Footer Section */}
        <footer className="bg-white py-6 mt-10 mx-3 shadow-lg">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <p className="text-gray-600 ml-2">© Rupesh 2024</p>
            <div className="flex gap-4 mr-3">
  {/* Facebook */}
  <a href="https://www.facebook.com/outvert17" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:scale-150 delay-200 duration-150">
    <Facebook className="w-5 h-5" />
  </a>

  {/* LinkedIn */}
  <a href="https://www.linkedin.com/in/rupesh-sshhh/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:scale-150 delay-200 duration-150">
    <Linkedin className="w-5 h-5" />
  </a>
</div>

          </div>
        </footer>
      </motion.article>
    </>
  );
};

export default SingleBlogPost;
