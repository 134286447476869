import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import React from 'react';

interface NavLinkProps {
  to: string;
  label: string;
  onClick?: () => void;
  isMobile?: boolean;
  className?: string;
}

const NavLink = ({ to, label, onClick, isMobile = false, className = '' }: NavLinkProps) => {
  return (
    <motion.div
      className={`relative group ${className}`} // group class to group the hover state
      whileHover="hover"
      initial="initial"
      animate="initial"
    >
      <Link
        to={to}
        smooth={true}
        duration={500}
        className={`
          relative inline-block text-white hover:text-white/90 transition-colors duration-300
          ${isMobile ? 'block px-3 py-2 text-base font-medium' : 'px-3 py-2 text-sm lg:text-xl font-medium'}
        `}
        onClick={onClick}
      >
        {label}
      </Link>

      {/* Animated underline effect */}
      <motion.div
        className="absolute bottom-0 left-0 right-0 h-[2px] bg-white"
        variants={{
          initial: { scaleX: 0, originX: 0 },
          hover: { 
            scaleX: 1,
            transition: {
              type: "spring",
              stiffness: 100, // Spring effect for smooth animation
              damping: 25,    // Controls the bounce of the spring
              duration: 0.4   // Time it takes to animate
            }
          }
        }}
      />
    </motion.div>
  );
};

export default NavLink;
