import React from 'react';
import { motion } from 'framer-motion';
import { NewsletterSubscribe } from '../Newsletter/NewsletterSubscribe'; // Import the NewsletterSubscribe component

const About = () => {
  return (
    <section id="about" className="mb-16 py-10 mt-0 md:mt-0 lg:mb-20 lg:mt-14">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.75, ease: 'easeOut' }} // Slower transition for a smoother effect
            viewport={{ once: true }}
            className="flex flex-col md:flex-row items-center gap-12"
          >
            <div className="md:w-1/2">
              {/* Image with smooth zoom-in and hover effect */}
              <motion.img
                src="/newsletter.png"
                alt="Team collaboration"
                className="rounded-2xl h-96 shadow-2xl transition-transform duration-500 ease-in-out transform hover:scale-110"
                whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            </div>
            <div className="md:w-1/2 space-y-6">
              <NewsletterSubscribe /> {/* Insert the newsletter subscribe form here */}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
