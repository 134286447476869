import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import BlogCard from './BlogCard';
import BlogHeader from './BlogHeader';
import BlogCategories from './BlogCategories';
import axios from 'axios';
import { BlogPost, BlogResponse } from './types';

const BlogSection = () => {
  // State for all fetched blog posts
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [visiblePosts, setVisiblePosts] = useState<BlogPost[]>([]);

  // State to control visibility of buttons
  const [showViewAll, setShowViewAll] = useState(true);
  const [showViewLess, setShowViewLess] = useState(false);

  // Fetch blog posts when the component is mounted
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get<BlogResponse>(
          "https://portfolio-backend-y0el.onrender.com/api/blog/bloglist",
          { withCredentials: true }
        );

        setBlogPosts(res.data.blogs);
        console.log("reading time is hai",res.data.blogs)
        setVisiblePosts(res.data.blogs.slice(0, 3)); // Initially display only the first 3 posts
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
  
    fetchBlogs(); // Call the function to fetch blogs
  }, []);
  
  // Show all posts when the "View All Posts" button is clicked
  const handleViewAll = () => {
    setVisiblePosts(blogPosts); // Show all blog posts
    setShowViewAll(false);
    setShowViewLess(true);
  };

  // Show fewer posts when the "View Less Posts" button is clicked
  const handleViewLess = () => {
    setVisiblePosts(blogPosts.slice(0, 3)); // Show only the first 3 posts
    setShowViewAll(true);
    setShowViewLess(false);
  };

  return (
    <section className="pb-4 bg-black text-white mt-0 mb-8 lg:mb-14 lg:mt-0">
      <div className="container mx-auto px-4">
        <BlogHeader />
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12 lg:mt-8"
        >
          {/* Map through visible posts and render BlogCard for each */}
          {visiblePosts.map((post, index) => (
            <BlogCard 
              key={post._id}
              content={post.content}
              title={post.title}
              image={post.images[0]}  // Assuming images is an array
              dateOfPublished={post.dateOfPublished}
              readingtime={post.readingtime}
              delay={index * 0.1}
              id={post._id}
            />
          ))}
        </motion.div>

        {/* Show "View All Posts" button if applicable */}
        {showViewAll && (
          <div className="text-center mt-12">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleViewAll}
              className="px-8 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors duration-300 inline-flex items-center gap-2"
            >
              View All Posts
            </motion.button>
          </div>
        )}

        {/* Show "View Less Posts" button if applicable */}
        {showViewLess && (
          <div className="text-center mt-12">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleViewLess}
              className="px-8 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors duration-300 inline-flex items-center gap-2"
            >
              View Less Posts
            </motion.button>
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogSection;
